table.bingocard {
    table-layout: fixed;
    border: 2px solid black;
    text-align: center;
    background-color: var(--light);
    color: black !important;
    width: 100%;
}
table.bingocard > thead > tr > th {
    border: 1px solid black;
    width: 20%;
    overflow-wrap: break-word;
    word-break: break-all;
    cursor: default;
}
table.bingocard > tbody > tr > td {
    border: 1px solid black;
    width: 20%;
    overflow-wrap: break-word;
    cursor: pointer;
}
table.bingocard > thead > tr > th > div.wrapper,
table.bingocard > tbody > tr > td > div.wrapper {
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: 0.25s;
}

.marker-input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 5px;
}
.marker-input:hover {
    background-color: rgba(100, 100, 100, 10);
    border: none;
}

.twitch-stream-iframe {
    flex-grow: 3;
    min-width: 300px;
    aspect-ratio: 16/9;
}
.twitch-chat-iframe {
    flex-grow: 1;
    min-height: 600px;
    min-width: 200px;
}
.btn-toggle {
    width: 30px;
    height: 30px;
    font-size: large;
    text-align: center;
    padding: 0 !important;
    border-radius: 0.1rem !important;
}

.key {
    background-color: #eee;
    color: black;
}
.claimed {
    text-decoration: line-through !important;
}

input[type='checkbox']:hover {
    cursor: pointer;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .nav-link {
        font-size: 1em !important;
    }
    .bingocard, .wrapper {
        margin: 0 !important;
        font-size: 0.85em !important;
    }
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 768px) {
    th, td {
        font-size: 0.75em !important;
    }
    .nav-link {
        font-size: 1em !important;
    }
    .btn-toggle {
        width: 25px;
        height: 25px;
    }
    .calls-container {
        max-width: 100% !important;
    }
    .modview-container, .bingo-container, .check-wrapper, .split-container-inner > .container {
        padding: 0 !important;
    }
    .btn-shrink {
        padding: 0.4em 1.5em !important;
    }
    .list-group-item, .modal-body {
        padding: 0.4em !important;
    }
    .stream-size-toggle {
        visibility: hidden;
    }
}