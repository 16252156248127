.modal-content {
    background-color: black !important;
    border: 6px solid var(--accent1-dark);
    border-radius: 20px;
}

.modal-header {
    border: none;
}

.modal-title {
    font-weight: 300 !important;
    font-size: 1.2em;
}

.modal-body {
    border: none;
}

.modal-footer {
    border: none;
}

.text-dark {
    color: black !important;
}

table {
    color: var(--light) !important;
    width: 100% !important;
}

.table-hover>tbody>tr {
    background-color: none;
}

.table-hover>tbody>tr:hover {
    background-color: var(--accent2) !important;
    color: black !important;
    cursor: pointer;
}

.settings-label {
    width: 180px !important;
    font-weight: 400;
}

.settings-content {
    width: 100%;
}

.settings-content-small {
    width: 25%;
    min-width: 150px;
}

.content-wrapper {
    padding: 1rem;
}
.create-game-body {
    padding: 1em;
}
.prompt-input {
    width: 100% !important;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 768px) {
    h2 {
        font-size: 1.2em;
    }
    .table, input, .select-tz, .game-detail-select {
        font-size: 0.8em !important;
    }
    ul {
        font-size: 0.8em;
    }
    .content-wrapper, .create-game-body {
        padding: 0;
    }
    .form-group {
        margin: 0.7em !important;
    }
    .game-detail-header {
        font-size: 0.8em !important;
    }
    .game-detail-button {
        font-size: 1em !important;
        padding: 0.3em 0.6em !important;
    }
    .game-detail-body {
        font-size: 1em;
    }
    .game-detail-body > ul {
        font-size: 0.6em;
    }
    .game-detail-body > ul > .nav-item > .nav-link {
        margin: 0 !important;
    }
    .form-row {
        width: 100% !important;
        flex-direction: column !important;
        align-items: start !important;
    }
    .col-1 {
        width: unset !important;
    }
}

