.news-item a {
    color: var(--accent1) !important;
}
.news-item a:hover {
    color: var(--accent1-dark) !important;
}
.feature-btn {
    background-color: black;
    color: var(--light);
    border: 5px solid var(--accent1);
    padding: 16px;
}
.feature-btn:hover {
    background-color: var(--accent1);
}
.btn-wrapper {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    height: 100%;
    width: 100%;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
}
.btn-wrapper .btn-path {
    transition: 0.3s;
    border: none;
    stroke-width: 6;
    fill: black;
    stroke: var(--accent1-dark);
    stroke-linecap: round;
    stroke-linejoin: round;
}
.btn-wrapper:hover .btn-path {
    fill: var(--accent1-dark);
}
.btn-inner {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--light);
    font-size: 1.9em;
    overflow: wrap;
    line-height: 1.2;
    color: var(--light);
    font-weight: 200;
}
.btn-icon {
    font-size: 2.5em;
}
.btn-text {
    font-size: 1em;
}

.news-item {
    background-color: var(--light);
    color: black;
    padding: 25px;
    border-radius: 20px;
    font-size: 1.1em;
}
img {
    max-width: 100%;
}


 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .btn-icon {
        font-size: 1em;
        margin-top: 0.7em;
    }
    .btn-text {
        font-size: 0.4em;
    }
    .news-item {
        font-size: 0.85rem;
        padding: 15px;
    }
    .news-container {
        padding: 0.5em !important;
        border-width: 0.2em !important;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .btn-icon {
        font-size: 2.2em;
    }
    .btn-text {
        font-size: 0.8em;
    }
 }

 @media only screen and (max-width: 1200px) {
    .nav-link {
        font-size: 1.1em !important;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .btn-icon {
        font-size: 2.5em;
    }
    .btn-text {
        font-size: 1em;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {

 } 