:root {
    --dark: #222;
    --light: #eee;
    --accent1: #a30505;
    --accent1-dark: #500a0a;
    --accent2: #ffe00f;
    --accent2-dark: rgb(143, 113, 32);
}
body {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
    background-color: var(--dark);
    color: var(--light);
}
a {
    color: var(--accent2);
}
a:hover {
    color: var(--accent2-dark);
}
.navbar-brand {
    color: var(--light) !important;
    font-size: 1.4em;
    font-weight: 400;
}
.nav-link {
    color: var(--light) !important;
    font-size: 1.4em;
    font-weight: 200;
    margin: 0 5px;
}
.active {
    color: var(--accent2) !important;
}
.navbar-brand:hover, .nav-link:hover {
    color: var(--accent2) !important;
    cursor: pointer;
}
.navbar-toggler-icon {
    background-color: var(--light);
    border-radius: 5px;
    padding: 5px;
}
.active-tab {
    color: var(--accent2) !important;
    border-bottom: 3px solid var(--accent1);
}

.btn-accent1 {
    transition: 0.3s;
    background-color: var(--accent1);
    color: var(--light);
    border-radius: 30px;
    padding: 10px 30px;
    outline: none !important;
    border: none !important;
}
.btn-accent1:hover {
    transition: 0.3s;
    background-color: var(--accent1-dark);
    color: var(--light);
    outline: none !important;
    border: none !important;
}
.btn-accent1:focus {
    transition: 0.3s;
    background-color: var(--accent1-dark);
    color: var(--light);
    outline: none !important;
    border: none !important;
}
.btn-accent2 {
    transition: 0.3s;
    background-color: var(--accent2) !important;
    color: black !important;
    border-radius: 30px;
    padding: 10px 30px;
    outline: none !important;
    border: none !important;
}
.btn-accent2:hover {
    transition: 0.3s;
    background-color: var(--accent2-dark) !important;
    color: black !important;
    outline: none !important;
    border: none !important;
}
.btn-accent2:focus {
    transition: 0.3s;
    background-color: var(--accent2-dark) !important;
    color: black !important;
    outline: none !important;
    border: none !important;
}
.btn-twitch {
    transition: 0.3s;
    background-color: #6441A4;
    color: var(--light);
    border-radius: 10px;
    padding: 20px 30px;
    outline: none !important;
    border: none !important;
}
.btn-twitch:hover {
    transition: 0.3s;
    background-color: #b19dd8;
    color: var(--light);
    outline: none !important;
    border: none !important;
}
.btn-twitch:focus {
    transition: 0.3s;
    background-color: #6441A4;
    color: var(--light);
    outline: none !important;
    border: none !important;
}

.btn-primary {
    transition: 0.3s;
    background-color: var(--accent1);
    color: var(--light);
    border-radius: 30px;
    padding: 10px 30px;
    outline: none !important;
    border: none !important;
}
.btn-primary:hover {
    transition: 0.3s;
    background-color: var(--accent1-dark);
    color: var(--light);
    outline: none !important;
    border: none !important;
}
.btn-primary:focus {
    transition: 0.3s;
    background-color: var(--accent1-dark);
    color: var(--light);
    outline: none !important;
    border: none !important;
}

.content-box {
    max-width: 1000px;
}

.emphasis-box {
    background-color: black;
    color: var(--light);
    border: 5px solid var(--accent1-dark);
    border-radius: 20px;
    padding: 5px;
}

input {
    border-radius: 12px !important;
}

.announcement {
    background-color: var(--accent1-dark);
    color: var(--light);
    border: 4px solid var(--accent1);
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 1.2em;
    width: 100%;
}

 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .split-container-section {
        width: 100%;
        padding: 5px;
        margin: 0 auto;
    }
    .split-container-flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .split-container-section {
        width: 100%;
        padding: 5px;
        margin: 0 auto;
    }
    .split-container-flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .split-container-section {
        width: 100%;
        padding: 10px;
        margin: 0 auto;
    }
    .split-container-flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .split-container-section {
        width: 100%;
        padding: 15px;
        margin: 0 auto;
    }
    .split-container-flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .split-container-inner {
        width: 50%;
        padding: 15px;
        margin: 0 auto;
    }
    .split-container-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
 } 