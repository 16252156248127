.profile-box {
    background-color: black;
    border: 5px solid var(--accent1-dark);
    border-radius: 15px;
    padding: 25px;
    min-width: 300px;
    max-width: 400px;
}

th {
    font-weight: 300;
}

.twitch-link {
    transition: 0.3s;
    padding: 10px;
    border-radius: 15px;
    background-color: var(--accent1-dark);
}
.twitch-link:hover {
    transition: 0.3s;
    background-color: var(--accent1);
}