.btn-star {
    transition: 0.3s;
    border-radius: 20px !important;
    background-color: var(--accent2) !important;
    color: var(--dark) !important;
    border: none !important;
    padding: 5px 10px !important;
}
.btn-star:hover {
    transition: 0.3s;
    background-color: var(--accent2-dark) !important;
    color: var(--dark) !important;
}

.geo-img-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
}
.geo-img-inner {
    position: absolute;
    top: 0;
    left: 0;
}

.zoom-icon {
    transition: 0.3s;
    background-color: var(--accent1);
    color: var(--light);
    padding: 8px 12px;
    border-radius: 50%;
}
.zoom-icon:hover {
    transition: 0.3s;
    background-color: var(--accent1-dark);
    padding: 8px 12px;
    border-radius: 50%;
}

.btn-map {
    transition: 0.3s;
    border-radius: 20px;
    background-color: var(--accent1);
    outline: none;
    border: none;
}
.btn-map:hover {
    transition: 0.3s;
    background-color: var(--accent1-dark);
}
.btn-map:disabled {
    transition: 0.3s;
    background-color: var(--accent1-dark);
}

input[type="checkbox"] {
    accent-color: var(--accent1);
}

.modal-content {
    background-color: black !important;
    border: 6px solid var(--accent1-dark);
    border-radius: 20px;
}
.modal-header {
    border: none;
    justify-content: space-between;
}
.modal-title {
    font-weight: 300 !important;
    font-size: 1.2em;
}
.modal-body {
    border: none;
}
.modal-footer {
    border: none;
}
.btn-modal {
    transition: 0.3s;
    border-radius: 20px;
    background-color: var(--accent1);
    outline: none;
    border: none;
}
.btn-modal:hover {
    transition: 0.3s;
    background-color: var(--accent1-dark);
}
.btn-modal:focus {
    transition: 0.3s;
    background-color: var(--accent1-dark);
}

.dot-guess {
    background-color: #FF8800;
    border: 2px solid #FFFF22;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}
.dot-answer {
    background-color: #008800;
    border: 2px solid #44FF44;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

@media only screen and (max-width: 600px) {
    .btn-icon {
        font-size: 1em;
        margin-top: 1em;
    }
    .btn-text {
        font-size: 0.4em;
        margin-top: 0.7em;
    }
    .btn-star {
        font-size: 0.6em;
    }
    h3 {
        font-size: 1em !important;
    }
}

@media only screen and (max-width: 768px) {
    .split-container-wrapper {
        justify-content: center !important;
        flex-wrap: wrap !important;
    }
    .btn-icon {
        font-size: 1.5em;
        margin-top: 1em;
    }
    .btn-text {
        font-size: 0.6em;
        margin-top: 0.7em;
    }
    .btn-map, .btn-skip, .btn-star {
        font-size: 0.8em;
    }
}