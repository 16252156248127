.form-check-input {
    border: none;
}
.form-check-input:checked {
    background-color: var(--accent1);
    border: none;
}

.loadout-row {
    padding: 5px 0;
}

.item-label {
    max-width: 100px;
    text-wrap: wrap;
    text-align: center;
}
.weapon-label {
    margin-top: .5rem;
}
.loadout-row, .loadout-row > div > img {
    max-width: 100%;
}